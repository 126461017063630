<template>
  <!-- MODAL VERIFY AUTHEN MOBILE -->
  <Modal @close-modal="$emit('close-modal',$event)"
         class="modal-center">
    <p
      v-if="titleTop"
      v-html="titleTop"
      class="title-top"
      slot="body"
    ></p>

    <ValidationObserver
      slot="body"
      tag="form"
      ref="observer"
      @keydown.enter="validateBeforeSubmit"
      vid="form"
      @submit.prevent
      class="form">
      <!-- TITLE POPUP -->
      <h2 v-if="!isSkip">本人認証の為、着信認証を行う電話番号を入力してください。</h2>

      <!-- FORM INPUT PHONE NUMBER -->
      <div class="form__input-phone">
        <!-- input phone number japanese-->
        <div class="form__control">
          <label>
            電話番号
          </label>
          <div class="form__control__input w-100">
            <InputField v-model="form.phone"
                        placeholder="例）09012345678"
                        type="tel"
                        class="w-100"
                        vid="phone"
                        :disabled="phoneOverseas ? true : false"
                        :rules="!phoneOverseas ? 'required|only_number_halfwidth|phone_minmax:10,12' : ''"
                        field="電話番号"/>
          </div>
        </div>

        <!-- <button class="btn-switch-phone"
                type="button"
                v-text="!this.phoneOverseas ? '▼日本以外の電話番号をご利用の方はこちら' : '▲日本の電話番号をご利用の方はこちら'"
                @click.prevent="changeOptionPhone"/> -->

        <template v-if="phoneOverseas">
          <!-- select countries -->
          <div class="form__control mb-3">
            <label>
              国/地域
            </label>
            <div class="form__control__input w-100">
              <InputSelect v-model="form.area_code"
                           :value-option="'callingCode'"
                           :name-option="'name'"
                           field="国/地域"
                           vid="area_code"
                           class="no-required"
                           :options="OPTION_COUNTRIES"/>
            </div>
          </div>

          <!-- input phone number -->
          <div class="form__control">
            <label>
              電話番号
            </label>
            <div class="form__control__input d-flex align-items-start w-100">
              <span class="area-code">{{ form.area_code }}</span>
              <InputField v-model="form.phone_overseas"
                          placeholder="例）9012345678"
                          vid="phone_overseas"
                          class="w-100"
                          type="tel"
                          rules="required|only_number_halfwidth|phone_minmax:1,14"
                          field="電話番号"/>
            </div>
          </div>
        </template>
      </div>
    </ValidationObserver>

    <!-- footer modal -->
    <div slot="footer" :class="{ 'btn--groups': isSkip }">
      <button @click.prevent="validateBeforeSubmit"
              type="submit"
              class="btn btn__submit"
              :disabled="(form.phone || form.phone_overseas) ? false : true"
              v-text="'電話番号を確認する'"/>

      <p
        v-if="titleBottom"
        v-html="titleBottom"
        class="title-bottom"
      ></p>

      <button type="button"
              class="btn btn__skip"
              v-text="'スキップする'"
              @click="$emit('close-modal',$event)"
              v-if="isSkip"/>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/advertising-detail/Modal'
import InputField from '@/components/form/InputField'
import { mapActions, mapState } from 'vuex'
import { OPTION_COUNTRIES } from '@/enum/countries'
import { SCRIPT_OSTIARIES } from '@/enum/options'
import InputSelect from '@/components/form/InputSelect'
import Common from '@/mixins/common.mixin'

export default {
  name: 'ModalVerifyAuthenMobile',

  mixins: [Common],

  components: { InputSelect, InputField, Modal },

  props: {
    isSkip: {
      type: Boolean,
      default: false
    },
    titleTop: {
      type: String,
      default: ''
    },
    titleBottom: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      scriptOstiaries: '',
      phoneOverseas: false,
      form: {
        phone: '',
        phone_overseas: '',
        area_code: '+7'
      },
      urlApi: process.env.VUE_APP_ROOT_API,
      OPTION_COUNTRIES,
      SCRIPT_OSTIARIES
    }
  },

  beforeDestroy () {
    // remove script ostiaries
    if (this.scriptOstiaries) document.head.removeChild(this.scriptOstiaries)
  },

  computed: {
    ...mapState('auth', ['profile'])
  },

  methods: {
    ...mapActions('user', ['checkPhone']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.checkVerifyPhone()
      }
    },

    checkVerifyPhone () {
      // call API Verify number
      let currentPhone = this.form.phone ? this.form.phone : this.form.phone_overseas
      const phoneOrigin = currentPhone

      // remove the first 0 in phone number
      if (currentPhone.charAt(0) === '0') {
        currentPhone = currentPhone.slice(1)
      }

      // assign phone number area code
      const currentArea = this.form.phone ? '+81' : this.form.area_code
      const phoneVerify = currentArea + currentPhone

      const params = {
        phone: phoneVerify.toString()
      }

      this.checkPhone(params).then(res => {
        if (res.success) this.redirectOstiaries(phoneOrigin.toString(), phoneVerify.toString())
        else if (res.status === 400) {
          this.$refs.observer.setErrors({
            [this.form.phone ? 'phone' : 'phone_overseas']: res.error.message
          })
        }
      })
    },

    redirectOstiaries (phoneOrigin, phoneVerify) {
      // add loading
      this.$store.dispatch('loader/pending')

      // detect full url ostiaries to add in script
      // const areaUrl = this.form.phone_overseas ? SCRIPT_OSTIARIES.urlOverseas : SCRIPT_OSTIARIES.urlDomestic
      const isDevelopment = process.env.NODE_ENV === 'development'
      const areaUrl = isDevelopment ? SCRIPT_OSTIARIES.urlOverseas : SCRIPT_OSTIARIES.urlDomestic

      const urlSuccess = window.location.origin + window.location.pathname + '?ostiaries_error=false'
      const urlError = window.location.origin + window.location.pathname + '?ostiaries_error=true'
      // parse param base 64 to BE decode and get param
      const paramOkUrl = {
        phoneOrigin,
        phone: phoneVerify,
        url_success: urlSuccess,
        url_error: urlError,
        user_id: this.profile.id
      }
      const paramEncodeBase64 = btoa(JSON.stringify(paramOkUrl))
      const okUrl = this.urlApi + `/users/verify-key?param=${paramEncodeBase64}`
      const backUrl = window.location.origin + window.location.pathname + '?ostiaries_error=true'
      const fullUrl = areaUrl + `&ok_url=${okUrl}&back_url=${backUrl}`

      // add tag script
      this.scriptOstiaries = this.addTagScript(fullUrl)

      // detect phone before call to in screen ostiaries
      let currentPhone = this.form.phone ? this.form.phone : this.form.phone_overseas
      // remove the first 0 in phone number
      if (currentPhone.charAt(0) === '0') {
        currentPhone = currentPhone.slice(1)
      }

      // check phone in japanese --> replace code area to 0
      currentPhone = this.form.phone ? `0${currentPhone}` : phoneVerify

      // set timeout to wait script added in header and can use function call_auth_run
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        call_auth_run(currentPhone)
      }, 2000)
    },

    changeOptionPhone () {
      // toggle option phone Overseas
      this.phoneOverseas = !this.phoneOverseas

      // reset validate
      this.$refs.observer.reset()

      // reset field phone other
      this.form.phone = ''
      this.form.phone_overseas = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  padding: 22px 25px;
  border-radius: 6px;
  background: #E7E5DE;
  margin-bottom: 26px;
  text-align: left;
  @media #{$info-phone} {
    padding: 17px 18px;
    margin-bottom: 20px;
  }
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    @include font-size(20px);
  }
  &__control {
    display: flex;
    text-align: left;
    @media #{$info-phone} {
      flex-direction: column;
    }
    label {
      margin-top: 13px;
      flex-shrink: 0;
      min-width: 120px;
      @include font-size(18px);
      @media #{$info-phone} {
        margin: 0 0 5px;
        width: 100%;
      }
    }
    input {
      line-height: 1;
    }
  }
}
.area-code {
  font-family: $font-family-title;
  @include font-size(20px);
  padding: 11px 14px;
  flex-shrink: 0;
  margin-right: 5px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 6px;
  @media #{$info-phone} {
    font-size: 16px;
    height: 42px;
    line-height: 1.3;
  }
}
.btn-switch-phone {
  margin: 20px auto;
  padding: 8px;
  display: flex;
  background: #C66C44;
  color: #FFFFFF;
  border-radius: 6px;
  @media #{$info-phone} {
    width: 100%;
    justify-content: center;
    font-size: 11px;
    padding: 5px;
    margin: 10px auto;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
  max-width: 357px;
  width: 100%;
  height: 51px;
  background: #C66C44;
  border-radius: 6px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  &:disabled {
    background: #444444;
    opacity: 0.4;
  }
  @media #{$info-phone} {
    height: 44px;
    font-size: 16px;
  }
  &__skip {
    opacity: 0.8;
    background-color: inherit;
    color: #282828;
    opacity: 0.5;
    font-size: 0.9rem;
    &:hover {
      color: #C66C44;
    }
  }
}

.title {
  &-top {
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 1.1rem;
  }
  &-bottom {
    font-size: 0.9rem;
    padding-top: 30px;
    padding-bottom: 10px;
  }
}
</style>
