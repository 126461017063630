export const OPTION_COUNTRIES = [
  {
    name: 'AB',
    callingCode: '+7'
  },
  {
    name: 'Afghanistan',
    callingCode: '+93'
  },
  {
    name: 'Albania',
    callingCode: '+355'
  },
  {
    name: 'Algeria',
    callingCode: '+213'
  },
  {
    name: 'AmericanSamoa',
    callingCode: '+1'
  },
  {
    name: 'Andorra',
    callingCode: '+376'
  },
  {
    name: 'Angola',
    callingCode: '+244'
  },
  {
    name: 'Anguilla',
    callingCode: '+1'
  },
  {
    name: 'Antigua & Barbuda',
    callingCode: '+1'
  },
  {
    name: 'Argentina',
    callingCode: '+54'
  },
  {
    name: 'Armenia',
    callingCode: '+374'
  },
  {
    name: 'Aruba',
    callingCode: '+297'
  },
  {
    name: 'Australia',
    callingCode: '+61'
  },
  {
    name: 'Austria',
    callingCode: '+43'
  },
  {
    name: 'Azerbaijan',
    callingCode: '+994'
  },
  {
    name: 'Bahamas',
    callingCode: '+1'
  },
  {
    name: 'Bahrain',
    callingCode: '+973'
  },
  {
    name: 'Bangladesh',
    callingCode: '+880'
  },
  {
    name: 'Barbados',
    callingCode: '+1'
  },
  {
    name: 'Belarus',
    callingCode: '+375'
  },
  {
    name: 'Belgium',
    callingCode: '+32'
  },
  {
    name: 'Belize',
    callingCode: '+501'
  },
  {
    name: 'Benin',
    callingCode: '+229'
  },
  {
    name: 'Bermuda',
    callingCode: '+1'
  },
  {
    name: 'Bhutan',
    callingCode: '+975'
  },
  {
    name: 'Bolivia',
    callingCode: '+591'
  },
  {
    name: 'Bosnia & Herzegovina',
    callingCode: '+387'
  },
  {
    name: 'Botswana',
    callingCode: '+267'
  },
  {
    name: 'Brazil',
    callingCode: '+55'
  },
  {
    name: 'BritishVirginIslands',
    callingCode: '+1'
  },
  {
    name: 'Brunei',
    callingCode: '+673'
  },
  {
    name: 'Bulgaria',
    callingCode: '+359'
  },
  {
    name: 'BurkinaFaso',
    callingCode: '+226'
  },
  {
    name: 'Burundi',
    callingCode: '+257'
  },
  {
    name: 'Cambodia',
    callingCode: '+855'
  },
  {
    name: 'Cameroon',
    callingCode: '+237'
  },
  {
    name: 'Canada',
    callingCode: '+1'
  },
  {
    name: 'CapeVerde',
    callingCode: '+238'
  },
  {
    name: 'CaribbeanNetherlands',
    callingCode: '+599'
  },
  {
    name: 'CaymanIslands',
    callingCode: '+1'
  },
  {
    name: 'CentralAfricanRepublic',
    callingCode: '+236'
  },
  {
    name: 'Chad',
    callingCode: '+235'
  },
  {
    name: 'Chile',
    callingCode: '+56'
  },
  {
    name: 'China',
    callingCode: '+86'
  },
  {
    name: 'Colombia',
    callingCode: '+57'
  },
  {
    name: 'Comoros',
    callingCode: '+269'
  },
  {
    name: 'Congo-Brazzaville',
    callingCode: '+242'
  },
  {
    name: 'Congo-Kinshasa',
    callingCode: '+243'
  },
  {
    name: 'CookIslands',
    callingCode: '+682'
  },
  {
    name: 'CostaRica',
    callingCode: '+506'
  },
  {
    name: 'Croatia',
    callingCode: '+385'
  },
  {
    name: 'Cuba',
    callingCode: '+53'
  },
  {
    name: 'Curaçao',
    callingCode: '+599'
  },
  {
    name: 'Cyprus',
    callingCode: '+357'
  },
  {
    name: 'Czechia',
    callingCode: '+420'
  },
  {
    name: 'Côted’Ivoire',
    callingCode: '+225'
  },
  {
    name: 'Denmark',
    callingCode: '+45'
  },
  {
    name: 'Djibouti',
    callingCode: '+253'
  },
  {
    name: 'Dominica',
    callingCode: '+1'
  },
  {
    name: 'DominicanRepublic',
    callingCode: '+1'
  },
  {
    name: 'Ecuador',
    callingCode: '+593'
  },
  {
    name: 'Egypt',
    callingCode: '+20'
  },
  {
    name: 'ElSalvador',
    callingCode: '+503'
  },
  {
    name: 'EquatorialGuinea',
    callingCode: '+240'
  },
  {
    name: 'Eritrea',
    callingCode: '+291'
  },
  {
    name: 'Estonia',
    callingCode: '+372'
  },
  {
    name: 'Eswatini',
    callingCode: '+268'
  },
  {
    name: 'Ethiopia',
    callingCode: '+251'
  },
  {
    name: 'FalklandIslands',
    callingCode: '+500'
  },
  {
    name: 'FaroeIslands',
    callingCode: '+298'
  },
  {
    name: 'Fiji',
    callingCode: '+679'
  },
  {
    name: 'Finland',
    callingCode: '+358'
  },
  {
    name: 'France',
    callingCode: '+33'
  },
  {
    name: 'FrenchGuiana',
    callingCode: '+594'
  },
  {
    name: 'FrenchPolynesia',
    callingCode: '+689'
  },
  {
    name: 'Gabon',
    callingCode: '+241'
  },
  {
    name: 'Gambia',
    callingCode: '+220'
  },
  {
    name: 'Georgia',
    callingCode: '+995'
  },
  {
    name: 'Germany',
    callingCode: '+49'
  },
  {
    name: 'Ghana',
    callingCode: '+233'
  },
  {
    name: 'Gibraltar',
    callingCode: '+350'
  },
  {
    name: 'Greece',
    callingCode: '+30'
  },
  {
    name: 'Greenland',
    callingCode: '+299'
  },
  {
    name: 'Grenada',
    callingCode: '+1'
  },
  {
    name: 'Guadeloupe',
    callingCode: '+590'
  },
  {
    name: 'Guam',
    callingCode: '+1'
  },
  {
    name: 'Guatemala',
    callingCode: '+502'
  },
  {
    name: 'Guinea',
    callingCode: '+224'
  },
  {
    name: 'Guinea-Bissau',
    callingCode: '+245'
  },
  {
    name: 'Guyana',
    callingCode: '+592'
  },
  {
    name: 'Haiti',
    callingCode: '+509'
  },
  {
    name: 'Honduras',
    callingCode: '+504'
  },
  {
    name: 'HongKongSARChina',
    callingCode: '+852'
  },
  {
    name: 'Hungary',
    callingCode: '+36'
  },
  {
    name: 'Iceland',
    callingCode: '+354'
  },
  {
    name: 'India',
    callingCode: '+91'
  },
  {
    name: 'Indonesia',
    callingCode: '+62'
  },
  {
    name: 'Iran',
    callingCode: '+98'
  },
  {
    name: 'Iraq',
    callingCode: '+964'
  },
  {
    name: 'Ireland',
    callingCode: '+353'
  },
  {
    name: 'IsleofMan',
    callingCode: '+44'
  },
  {
    name: 'Israel',
    callingCode: '+972'
  },
  {
    name: 'Italy',
    callingCode: '+39'
  },
  {
    name: 'Jamaica',
    callingCode: '+1'
  },
  {
    name: 'Jersey',
    callingCode: '+44'
  },
  {
    name: 'Jordan',
    callingCode: '+962'
  },
  {
    name: 'Kazakhstan',
    callingCode: '+7'
  },
  {
    name: 'Kenya',
    callingCode: '+254'
  },
  {
    name: 'Kiribati',
    callingCode: '+686'
  },
  {
    name: 'Kuwait',
    callingCode: '+965'
  },
  {
    name: 'Kyrgyzstan',
    callingCode: '+996'
  },
  {
    name: 'Laos',
    callingCode: '+856'
  },
  {
    name: 'Latvia',
    callingCode: '+371'
  },
  {
    name: 'Lebanon',
    callingCode: '+961'
  },
  {
    name: 'Lesotho',
    callingCode: '+266'
  },
  {
    name: 'Liberia',
    callingCode: '+231'
  },
  {
    name: 'Libya',
    callingCode: '+218'
  },
  {
    name: 'Lithuania',
    callingCode: '+370'
  },
  {
    name: 'Luxembourg',
    callingCode: '+352'
  },
  {
    name: 'MacaoSARChina',
    callingCode: '+853'
  },
  {
    name: 'Madagascar',
    callingCode: '+261'
  },
  {
    name: 'Malawi',
    callingCode: '+265'
  },
  {
    name: 'Malaysia',
    callingCode: '+60'
  },
  {
    name: 'Maldives',
    callingCode: '+960'
  },
  {
    name: 'Mali',
    callingCode: '+223'
  },
  {
    name: 'Malta',
    callingCode: '+356'
  },
  {
    name: 'Martinique',
    callingCode: '+596'
  },
  {
    name: 'Mauritania',
    callingCode: '+222'
  },
  {
    name: 'Mauritius',
    callingCode: '+230'
  },
  {
    name: 'Mayotte',
    callingCode: '+262'
  },
  {
    name: 'Mexico',
    callingCode: '+52'
  },
  {
    name: 'Micronesia',
    callingCode: '+691'
  },
  {
    name: 'Moldova',
    callingCode: '+373'
  },
  {
    name: 'Monaco',
    callingCode: '+377'
  },
  {
    name: 'Mongolia',
    callingCode: '+976'
  },
  {
    name: 'Montenegro',
    callingCode: '+382'
  },
  {
    name: 'Morocco',
    callingCode: '+212'
  },
  {
    name: 'Mozambique',
    callingCode: '+258'
  },
  {
    name: 'Myanmar(Burma)',
    callingCode: '+95'
  },
  {
    name: 'Namibia',
    callingCode: '+264'
  },
  {
    name: 'Nauru',
    callingCode: '+674'
  },
  {
    name: 'Nepal',
    callingCode: '+977'
  },
  {
    name: 'Netherlands',
    callingCode: '+31'
  },
  {
    name: 'NewCaledonia',
    callingCode: '+687'
  },
  {
    name: 'NewZealand',
    callingCode: '+64'
  },
  {
    name: 'Nicaragua',
    callingCode: '+505'
  },
  {
    name: 'Niger',
    callingCode: '+227'
  },
  {
    name: 'Nigeria',
    callingCode: '+234'
  },
  {
    name: 'NorthMacedonia',
    callingCode: '+389'
  },
  {
    name: 'NorthernMarianaIslands',
    callingCode: '+1'
  },
  {
    name: 'Norway',
    callingCode: '+47'
  },
  {
    name: 'Oman',
    callingCode: '+968'
  },
  {
    name: 'Pakistan',
    callingCode: '+92'
  },
  {
    name: 'PalestinianTerritories',
    callingCode: '+970'
  },
  {
    name: 'Panama',
    callingCode: '+507'
  },
  {
    name: 'PapuaNewGuinea',
    callingCode: '+675'
  },
  {
    name: 'Paraguay',
    callingCode: '+595'
  },
  {
    name: 'Peru',
    callingCode: '+51'
  },
  {
    name: 'Philippines',
    callingCode: '+63'
  },
  {
    name: 'Poland',
    callingCode: '+48'
  },
  {
    name: 'Portugal',
    callingCode: '+351'
  },
  {
    name: 'PuertoRico',
    callingCode: '+1'
  },
  {
    name: 'Qatar',
    callingCode: '+974'
  },
  {
    name: 'Romania',
    callingCode: '+40'
  },
  {
    name: 'Russia',
    callingCode: '+7'
  },
  {
    name: 'Rwanda',
    callingCode: '+250'
  },
  {
    name: 'Réunion',
    callingCode: '+262'
  },
  {
    name: 'Samoa',
    callingCode: '+685'
  },
  {
    name: 'SaudiArabia',
    callingCode: '+966'
  },
  {
    name: 'Senegal',
    callingCode: '+221'
  },
  {
    name: 'Serbia',
    callingCode: '+381'
  },
  {
    name: 'Seychelles',
    callingCode: '+248'
  },
  {
    name: 'SierraLeone',
    callingCode: '+232'
  },
  {
    name: 'Singapore',
    callingCode: '+65'
  },
  {
    name: 'SintMaarten',
    callingCode: '+1'
  },
  {
    name: 'Slovakia',
    callingCode: '+421'
  },
  {
    name: 'Slovenia',
    callingCode: '+386'
  },
  {
    name: 'SolomonIslands',
    callingCode: '+677'
  },
  {
    name: 'Somalia',
    callingCode: '+252'
  },
  {
    name: 'SouthAfrica',
    callingCode: '+27'
  },
  {
    name: 'SouthKorea',
    callingCode: '+82'
  },
  {
    name: 'SouthSudan',
    callingCode: '+211'
  },
  {
    name: 'Spain',
    callingCode: '+34'
  },
  {
    name: 'SriLanka',
    callingCode: '+94'
  },
  {
    name: 'St.Kitts & Nevis',
    callingCode: '+1'
  },
  {
    name: 'St.Lucia',
    callingCode: '+1'
  },
  {
    name: 'St.Martin',
    callingCode: '+590'
  },
  {
    name: 'St.Vincent & Grenadines',
    callingCode: '+1'
  },
  {
    name: 'Sudan',
    callingCode: '+249'
  },
  {
    name: 'Suriname',
    callingCode: '+597'
  },
  {
    name: 'Sweden',
    callingCode: '+46'
  },
  {
    name: 'Switzerland',
    callingCode: '+41'
  },
  {
    name: 'SãoTomé & Príncipe',
    callingCode: '+239'
  },
  {
    name: 'Taiwan',
    callingCode: '+886'
  },
  {
    name: 'Tajikistan',
    callingCode: '+992'
  },
  {
    name: 'Tanzania',
    callingCode: '+255'
  },
  {
    name: 'Thailand',
    callingCode: '+66'
  },
  {
    name: 'Timor-Leste',
    callingCode: '+670'
  },
  {
    name: 'Togo',
    callingCode: '+228'
  },
  {
    name: 'Tonga',
    callingCode: '+676'
  },
  {
    name: 'Trinidad & Tobago',
    callingCode: '+1'
  },
  {
    name: 'Tunisia',
    callingCode: '+216'
  },
  {
    name: 'Turkey',
    callingCode: '+90'
  },
  {
    name: 'Turkmenistan',
    callingCode: '+993'
  },
  {
    name: 'Turks & CaicosIslands',
    callingCode: '+1'
  },
  {
    name: 'Tuvalu',
    callingCode: '+688'
  },
  {
    name: 'U.S.VirginIslands',
    callingCode: '+1'
  },
  {
    name: 'Uganda',
    callingCode: '+256'
  },
  {
    name: 'Ukraine',
    callingCode: '+380'
  },
  {
    name: 'UnitedArabEmirates',
    callingCode: '+971'
  },
  {
    name: 'UnitedKingdom',
    callingCode: '+44'
  },
  {
    name: 'UnitedStates',
    callingCode: '+1'
  },
  {
    name: 'Uruguay',
    callingCode: '+598'
  },
  {
    name: 'Uzbekistan',
    callingCode: '+998'
  },
  {
    name: 'Vanuatu',
    callingCode: '+678'
  },
  {
    name: 'Venezuela',
    callingCode: '+58'
  },
  {
    name: 'Vietnam',
    callingCode: '+84'
  },
  {
    name: 'XK',
    callingCode: '+383'
  },
  {
    name: 'Yemen',
    callingCode: '+967'
  },
  {
    name: 'Zambia',
    callingCode: '+260'
  },
  {
    name: 'Zimbabwe',
    callingCode: '+263'
  }
]
